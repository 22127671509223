import './App.css';
import React from 'react';
import HomePage from './views/HomePage';
import ContactSalesView from './views/Contact';
import { BrowserRouter, Routes, Route } from 'react-router-dom';  // Import required components

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact-sales" element={<ContactSalesView />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
