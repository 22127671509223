import React, { useState, useEffect, useCallback } from 'react';
import Layout from '../components/Layout/Layout';
import HomeSection from '../components/HomeSection/HomeSection';
import AboutSection from '../components/AboutSection/AboutSection';
import ContactSection from '../components/ContactSection/ContactSection';
import ServicesSection from '../components/ServicesSection/ServicesSection';
import StatsSection from '../components/StatsSection/StatsSection';
import styles from './HomePage.module.scss';

const HomePage = () => {
    const [showScrollButton, setShowScrollButton] = useState(false);

    const checkScrollTop = useCallback(() => {
        // No dependency on showScrollButton's state here
        if (window.pageYOffset > 400) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
    }, []); // Empty dependency array

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        // Cleanup listener on unmount
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, [checkScrollTop]); // checkScrollTop is stable and won't change

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Layout>
            <div id="home">
                <HomeSection />
            </div>
            <div>
                <ContactSection />
            </div>
            <div id="about">
                <AboutSection />
            </div>
            <div>
                <StatsSection />
            </div>
            <div id="services">
                <ServicesSection />
            </div>
            <button
                onClick={scrollToTop}
                className={`${styles.scrollToTopButton} ${showScrollButton ? styles.show : ''}`}
            >
                &uarr;
            </button>
        </Layout>
    );
};

export default HomePage;
