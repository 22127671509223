import React from 'react';
import styles from './ServicesSection.module.scss';

const ServicesSection = () => {
  return (
    <section className={styles.servicesSection}>
      <div className={styles.content}>
        <h2 className={styles.tagline}>Our Services</h2>
        
        <div className={styles.cardsContainer}>
          
          <div className={styles.serviceCard}>
            <h3>SEO Automation</h3>
            <p>Leverage our proprietary SEO software to enhance your Google Business Profile optimization, ensuring your business stands out.</p>
          </div>
          
          <div className={styles.serviceCard}>
            <h3>Custom Website Design</h3>
            <p>We provide tailored web designs that align with your brand and engage your audience.</p>
          </div>

          <div className={styles.serviceCard}>
            <h3>Geo-Tagging & Automated Follow-ups</h3>
            <p>Boost your local search results with geo-tagging and ensure customer loyalty with our automated follow-up systems.</p>
          </div>

          <div className={styles.serviceCard}>
            <h3>Digital Partnership</h3>
            <p>We're here to be your full-service digital partner, ensuring your business thrives online.</p>
          </div>

        </div>

        {//<span className={styles.learnMore}>More Services</span>
        }
      </div>
    </section>
  );
};

export default ServicesSection;
