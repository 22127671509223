import React from 'react';
import styles from './AboutSection.module.scss';
import aboutUsImage from '../../assets/images/StockPhotos/React_js_Site.jpg'

const AboutSection = () => {
  return (
    <section className={styles.aboutSection}>
      <header className={styles.intro}>
        <h2>About Scorch SEO</h2>
        <p>Making SEO Simple and Automated for Every Small Business</p>
        <img src={aboutUsImage} alt="About Scorch SEO" />
      </header>
      
      <div className={styles.cardContainer}>
  <div className={styles.card}>
    <h3>Our Mission</h3>
    <p>Empower small businesses with innovative, semi-automated SEO and Web solutions. The more clients you can attract, the brighter our success shines.</p>
  </div>
  
  <div className={styles.card}>
    <h3>Our Journey</h3>
    <p>Founded in 2023 in the outside Austin, TX, we launched our proprietary SEO software a year later. This tool automates Google SEO for Google Business Profiles, bridging the gap between businesses and their digital potential.</p>
  </div>
</div>

      <div className={styles.cta}>
        <p>Ready to amplify your online presence?</p>
        <button>Contact Us</button>
      </div>
    </section>
  );
};

export default AboutSection;
