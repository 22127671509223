import React from 'react';
import styles from './Footer.module.scss';

// Import the images
import ScorchLogo from '../../assets/images/logo192.png';
//import FacebookIcon from '../../assets/images/link_images/f.png';
//import IGIcon from '../../assets/images/link_images/IG.png';
//import GoogleIcon from '../../assets/images/link_images/G.png';
import Logo from '../../assets/images/Scorch_Logo_w_bg.png';

const Footer = () => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;

  return (
    <footer className={styles.footer}>
      <div className={styles.topBar}>
        <span className={styles.poweredBy}>Powered By{' '}</span>
        <span>Scorch{' '}</span>
        <span className={styles.awkward}>
          {' '}
            <img
            src={ScorchLogo}
            alt="Scorch Logo"
            className={styles.companyLogo}
            width={isMobile ? 25 : 25}
            height={isMobile ? 25 : 25}
          />
        </span>
      </div>
      <div className={styles.socialMedia}>
        {/*<a
          href="https://www.facebook.com/capitaloverhead/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={FacebookIcon} // Update the src value
            alt="Facebook"
            className={styles.socialLink}
            width={30}
            height={30}
          />
        </a>
        <a
          href="https://www.instagram.com/capitaloverhead/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={IGIcon}
            alt="Instagram"
            className={styles.socialLink}
            width={30}
            height={30}
          />
        </a>
        <a
          href="https://www.google.com/maps/place/Capital+Overhead+Doors+and+Gates/@34.6072528,-97.2112981,15z/data=!4m6!3m5!1s0x87b2e776c5a46013:0x3fce84e440e07175!8m2!3d34.6072528!4d-97.2112981!16s%2Fg%2F11ft396n76?entry=ttu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={GoogleIcon}
            alt="Google"
            className={styles.socialLink}
            width={30}
            height={30}
          />
        </a>*/}
      </div>
      <div className={styles.bottomSection}>
        <div className={styles.boxesContainer}>
          <div className={styles.box}></div>
          <img
            src={Logo}
            alt="Scorch Logo"
            className={styles.companyLogo}
            width={isMobile ? 139 : 250}
            height={isMobile ? 100 : 180}
          />
          <div className={styles.box}></div>
        </div>
        <div className={styles.contactInfo}>
          <h1>Contact Us Today!</h1>
          {/*<p>services@capitaloverhead.com</p>*/}
          <a className={styles.phoneNumber} href="tel:4695856541">(469) 585-6541</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
