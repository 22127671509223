import React from 'react';

import Layout from '../components/Layout/Layout';
import ContactForm from '../components/ContactForm/ContactForm.js'; // Make sure this path points to your ContactForm component

const ContactSalesView = () => {
    return (
        <Layout>
        <div>
            <h1>Contact Sales</h1>
            <p>Fill out the form below and a member of our team will contact you as soon as possible.</p>
            <ContactForm />
        </div>
        </Layout>
    );
}

export default ContactSalesView;
