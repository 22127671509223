import React from 'react';
import styles from './HomeSection.module.scss';
import heroImage from '../../assets/images/StockPhotos/SEO_Success.jpg'

const HomeSection = () => {

    const scrollToServices = () => {
        const servicesElement = document.getElementById("services");
        if (servicesElement) {
            const yOffset = servicesElement.offsetTop;
            window.scrollTo({ top: yOffset, behavior: 'smooth' });
        }
    };

    return (
        <section className={styles.homeSection}>
            <div className={styles.content}>
                <h1 className={styles.tagline}>Elevate Your Digital Presence.</h1>
                <p className={styles.catchphrase}>Comprehensive SEO & Web Solutions to Drive Your Business Forward.</p>
                <button className={styles.ctaButton} onClick={scrollToServices}>Discover Our Services</button>
            </div>
            <div className={styles.imageContainer}>
                <img src={heroImage} alt="A representation of digital success" className={styles.heroImage}/>
            </div>
        </section>
    );
}

export default HomeSection;
