// ../components/ContactForm/ContactForm.js
import React, { useState } from 'react';
import axios from 'axios';
import styles from './ContactForm.module.scss';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    businessName: '',
    businessType: '',
    otherBusinessType: '',
    message: ''
  });
  const [formStatus, setFormStatus] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = formData;

    if (!validateEmail(email)) {
      setIsEmailValid(false);
      return;
    }

    setIsEmailValid(true);
    setFormStatus('loading');

    try {
      const response = await axios.post('/.netlify/functions/send-email', formData);

      if (response.status === 200) {
        setFormStatus('success');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          businessName: '',
          businessType: '',
          otherBusinessType: '',
          message: ''
        }); // Clear form
      } else {
        setFormStatus('error');
      }
    } catch (error) {
      setFormStatus('error');
    }
  };

  return (
    <div className={styles.contactFormContainer}>
      {formStatus === 'loading' && <p>Sending...</p>}
      {formStatus === '' && (
        <form onSubmit={handleSubmit}>
          {/* First Name Input */}
          <input
            className={styles.formInput}
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleInputChange}
            required
          />

          {/* Last Name Input */}
          <input
            className={styles.formInput}
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleInputChange}
            required
          />

          {/* Email Input */}
          <input
            className={`${styles.formInput} ${isEmailValid ? '' : styles.invalid}`}
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          {!isEmailValid && <p className={styles.errorMsg}>Please enter a valid email address.</p>}

          {/* Phone Number Input */}
          <input
            className={styles.formInput}
            type="tel"
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            required
          />

          {/* Business Name Input */}
          <input
            className={styles.formInput}
            type="text"
            name="businessName"
            placeholder="Business Name"
            value={formData.businessName}
            onChange={handleInputChange}
            required
          />

          {/* Business Type Select */}
          <select
            className={styles.formSelect}
            name="businessType"
            value={formData.businessType}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Business Type</option>
            <option value="service">Service</option>
            <option value="food">Food</option>
            <option value="retail">Retail</option>
            <option value="other">Other</option>
          </select>

          {/* Other Business Type Input */}
          {formData.businessType === 'other' && (
            <input
              className={styles.formInput}
              type="text"
              name="otherBusinessType"
              placeholder="Please specify"
              value={formData.otherBusinessType}
              onChange={handleInputChange}
            />
          )}

          {/* Message Textarea */}
          <textarea
            className={styles.formTextarea}
            name="message"
            placeholder="Any custom messages?"
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>

          {/* Submit Button */}
          <button className={styles.formButton} type="submit">
            Submit
          </button>
        </form>
      )}

      {formStatus === 'success' && (
        <div>
          <h3>Thanks for reaching out!</h3>
          <p>We've received your details and will be in touch with you shortly.</p>
        </div>
      )}

      {formStatus === 'error' && (
        <div>
          <h3>Oops!</h3>
          <p>Something went wrong. Please try again later or contact us directly.</p>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
