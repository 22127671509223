import React from 'react';
import styles from './ContactSection.module.scss';

const ContactSection = () => {
    return (
        <section className={styles.contactSection}>
            <div className={styles.content}>
                <div className={styles.taglineContainer}>
                    <p className={styles.catchphrase}>
                        Get ready to spread your business like a wildfire with Scorch SEO
                    </p>
                    <a className={styles.callButton} href="tel:4695856541">
                        Consult With Us
                    </a>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;
