import React, { useState, useRef, useEffect } from 'react';
import styles from './Navbar.module.scss';
import logo from '../../assets/images/Scorch_200x100.png';
import { Link } from 'react-router-dom';


const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuRef = useRef(null); // Reference to the mobile menu

  const isMainPage = window.location.pathname === "/"; // Check if on the main page
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;

  const toggleMobileMenu = (event) => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    event.stopPropagation(); // Stop the click from reaching the document
  };

  useEffect(() => {
    const closeMenu = () => {
      setIsMobileMenuOpen(false);
    };

    document.addEventListener("click", closeMenu);

    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, []);

  return (
    <nav className={`${styles.navbar}`}>
      <div className={styles.hamburger} onClick={toggleMobileMenu}>
        <div
          className={`${styles.hamburgerMenu} ${isMobileMenuOpen ? styles.open : ''
            }`}
        >
          <span className={styles.hamburgerLine}></span>
          <span className={styles.hamburgerLine}></span>
          <span className={styles.hamburgerLine}></span>
        </div>
      </div>
      <div className={styles.logoContainer}>
        <a href={isMainPage ? "#home" : "/"}>
          <img
            className={styles.logo}
            src={logo}
            alt="Logo"
            width={isMobile ? 140 : 420}
            height={isMobile ? 140 : 420}
          />
        </a>
      </div>
      <div className={styles.contactSales}>
        <Link to="/contact-sales" className={styles.contactButton}>Contact Sales</Link>
      </div>
      {isMobileMenuOpen && (
        <div className={styles.mobileMenu} ref={menuRef} onClick={(e) => e.stopPropagation()}>
          {isMainPage ? (
            <>
              <a href="#home" onClick={() => setIsMobileMenuOpen(false)}>Home</a>
              <a href="#about" onClick={() => setIsMobileMenuOpen(false)}>About Us</a>
              <a href="#services" onClick={() => setIsMobileMenuOpen(false)}>Services</a>
            </>
          ) : (
            <>
              <a href="/">Home</a>
              <a href="/about">About Us</a>
              <a href="/services">Services</a>
            </>
          )}
          <Link to="/login">Log In</Link>
          <Link to="/contact-sales">Contact Sales</Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
