import React from 'react';
//import Header from '../Header/Header';
import Navbar from '../Navbar/Navbar'; // Add this import
import Footer from '../Footer/Footer';
//import NavbarSplit from '../NavbarSplit/NavbarSplit';
import styles from './Layout.module.scss';

const Layout = ({ children }) => {
  return (
    <>
      <div className={styles.stickyContainer}>
        <Navbar />
      </div>
      <main className={styles.main}>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
