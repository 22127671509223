import React from 'react';
import styles from './StatsSection.module.scss';

const StatsSection = () => {
  return (
    <section className={styles.statisticBreak}>
       <p className={styles.statisticText}>
          "57% of B2B marketers stated that SEO generates more leads than any other marketing initiative."
       </p>
       <span className={styles.source}>- HubSpot</span>
    </section>
  );
};

export default StatsSection;
